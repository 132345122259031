<template>
  <div class="page-wrapper">
    <div class="title-content">
      <BreadCrumb>
        <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>

        <el-breadcrumb-item> 印花税测算</el-breadcrumb-item>
      </BreadCrumb>
      <div class="main-top">
        <MainHeader>
          <template slot="title">数据上传</template>
        </MainHeader>
        <SaSteps :steps="steps" :active-index="stepActive"></SaSteps>
        <div class="placeholder"></div>
      </div>
    </div>
    <div class="section-box">
      <div class="title">
        基础信息
      </div>
      <div class="user field">
        <div class="label">
          企业名称：
        </div>

        <el-input
          class="input"
          v-model.trim="form.companyName"
          placeholder="请输入"
        ></el-input>
      </div>
      <div class="password field">
        <div class="label">
          纳税人识别号：
        </div>

        <el-input
          class="input"
          v-model.trim="form.nsrsbh"
          placeholder="请输入"
        ></el-input>
      </div>
    </div>
    <SaFooter>

      <button
        class="btn btn-primary btn-next"
        @click="handleBtnNextClick"
      >
        下一步
      </button>
    </SaFooter>
  </div>
</template>

<script>
import BreadCrumb from "../../../components/BreadCrumb";
import MainHeader from "../../components/MainHeader";
import SaFooter from "../../Test/UploadTable/components/SaFooter";
import SaSteps from "../../components/SaSteps";
import {createStampTaxInspect} from "../../../api/yinhuashui";

export default {
  name: "HeTongTaiZhang",
  components: {
    SaFooter,
    BreadCrumb,
    MainHeader,
    SaSteps
  },
  data() {
    return {
      steps: ['基础信息', '合同台账上传', '印花税申报表上传', '提交成功'],
      stepActive: 0,
      form: {
        companyName: '',
        nsrsbh: '',
      },
      realName: JSON.parse(localStorage.getItem("user")).realName,
      firmId: JSON.parse(localStorage.getItem("user")).firmId,
    }
  },
  methods: {
    async handleBtnNextClick() {
      if (!this.form.companyName) {
        this.$message("请输入公司名称！");
        return;
      }
      if (!this.form.nsrsbh) {
        this.$message("请输入纳税人识别号！");
        return;
      }
      const res = await createStampTaxInspect({companyName:this.form.companyName,createUserName:this.realName,taxNo:this.form.nsrsbh,firmId:this.firmId})

      if(res.code==200){
        this.$router.push({
          name:'stampTax-heTongTaiZhang',
          params:{
            programId:res.data
          }

        })
      }


    }
  }
}
</script>

<style lang="stylus" scoped>
.page-wrapper {
  padding-bottom: 84px;

  .title-content {
    // width: 1260px;
    height: 96px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
  }

  .main-top {
    display: flex;
    justify-content: space-between;
  }

  .section-box {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    padding-bottom 50px

    .title {
      color: rgba(0, 0, 0, 0.8);
      line-height: 54px;
      font-size: 18px;
      font-weight: 600;
      border-bottom: 1px solid #eeeeee;
    }

    .field {
      margin: 16px 0 0 40px;
      display flex
      align-items center;
    }

    .field:before {
      content: "*";
      color: red;
      position: relative;
      right: 4px;
      top: 1px;
    }

    .label {

      width 100px
    }

    .input {
      width: 40%;
    }

  }


}
</style>
