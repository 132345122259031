import { render, staticRenderFns } from "./SaFooter.vue?vue&type=template&id=68429b36&scoped=true&"
import script from "./SaFooter.vue?vue&type=script&lang=js&"
export * from "./SaFooter.vue?vue&type=script&lang=js&"
import style0 from "./SaFooter.vue?vue&type=style&index=0&id=68429b36&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68429b36",
  null
  
)

export default component.exports