<template>
  <div class="processContainer">
    <template v-for="(step,index) in steps">
      <span class="process-status" v-if="index>0" :key="`a${index}`"></span>
      <div class="number" :class="{active:index<=activeIndex}" :key="`b${index}`">{{
          index + 1
        }}
      </div>
      <span class="text" :class="{active:index<=activeIndex}" :key="`c${index}`">{{ step }}</span>


    </template>
  </div>
</template>

<script>
export default {
  name: "SaSteps",
  props: {
    steps: Array,
    activeIndex: Number
  }
}
</script>

<style lang="stylus" scoped>
.processContainer {
  display: flex;
  align-item: center;
  position: relative;
  top: 6px;

  .number {
    position: relative;
    bottom: 1px;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    background #8A8A8A;
    color #fff
    border-radius 50%;
    display flex;
    font-size 12px
    justify-content: center;
    align-items: center;
  }

  .number.active {
    background: #000;
  }

  .text {
    font-size: 16px;

    color: rgba(0, 0, 0, 0.4);
  }

  .text.active {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
  }

  .process-status {
    width: 120px;
    height: 2px;
    background: rgba(217, 217, 217, 1);
    position: relative;
    top: 8px;
    margin: 0 24px;
  }
}
</style>
